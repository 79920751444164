// import * as React from "react";
import React, { Component, createRef } from "react";
import CheckList from "./CheckList";
import DocumentList, { DocList } from "./DocumentList";
import GeneralDetail from "./GeneralDetail";
import Overview from "./Overview";
import PropertyDescription from "./PropertyDescription";
import ScrutinizedDocument from "./ScrutinizedDocument";
import { Link } from "react-router-dom";
import { callApi } from "../../utils/api";
import AddOnFeature from "./AddOnFeature";
import HintAboutFile from "./HintAboutFile";
import { FRONTOFFICE_ROLE } from "../../utils/role";
import Select from "react-select";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore,
} from "react-toasts";
import CountdownTimer from "../file/CountdownTimer";
declare var $: any;
interface AllProps {
  match: any;
  history: any;
}
export default class WorkFile extends React.Component<AllProps> {
  overviewRef = createRef<Overview>();
  state = {
    doc_list: [],
    revenueCount: 0,
    AllDocumentShow: false,
    overVieWorking: false,
    documentTotal: 0,
    overViewShow: false,
    bank_templates: [],
    bank_id: 0,
    intime: 0,
    selectVal: false,
    setResponse: true,
    in_reason: "",
    info: {
      verify_officer: "",
      process_officer: "",
      app_id: "",
      doc_request: "",
      file_name: "",
      file_status: "",
      verify: "",
      report_status: "",
      pending: "",
      closed: "",
      total: "",
    },
    wizard: {
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      step6: false,
      step7: false,
    },
    id: 0,
    docList: [],
    initialTimeInMinutes: 0,
    updatedRemainingTime: 0,
    isLoading: false,
  };
  componentDidMount() {
    // this.getDocuments();
    this.getRevenueDocs();
    sessionStorage.setItem(
      "currentfileId",
      JSON.stringify(this.props.match.params.id)
    );

    // if (localStorage.getItem("BankTemplate")) {
    //   var bankTemp: any = localStorage.getItem("BankTemplate");
    //   this.setState({ bank_templates: JSON.parse(bankTemp) })
    // } else {
    //   console.log("cbsducbsdcs");
    // this.setState({ overViewShow: false })
    this.getTemplates();
    // }
    this.getFileDashboard();
    this.setState({ id: this.props.match.params.id });
    this.goToTab("step" + this.props.match.params.stepno);
    this.getInTime();
    sessionStorage.setItem("dashboard_status", "1");
  }
  countdownTimerRef: CountdownTimer | null = null;
  componentWillUnmount() {
    if (this.countdownTimerRef) {
      this.countdownTimerRef.clearInterval();
    }
    const process_office_id: any = sessionStorage.getItem("process_office_id");
    const userid1: any = sessionStorage.getItem("user1");
    // localStorage.setItem("verification_to_addon", JSON.stringify(false));
  }
  onchange = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/\s+/g, " ").trim(),
      selectVal: false,
    });
  };
  previousPop = () => {
    $("#in_time").modal("show");
    $("#another-reason").modal("hide");
  };
  closeToaster = () => {
    $("#alert_alldocs").modal("hide");
  };
  closeToasterOverview = () => {
    $("#alert_overview").modal("hide");
  };
  closeToasterSOP = () => {
    $("#alert_SOP").modal("hide");
  };
  OpenToaster = (notice: any) => {
    // if (notice == 1) {
    //   $("#alert_alldocs").modal("show");
    //   // ToastsStore.success("Please Type Your All Documents");
    //   setTimeout(() => {
    //     $("#alert_alldocs").modal("hide");
    //   }, 5000);
    // } else
    if (notice == 2) {
      $("#alert_SOP").modal("show");

      setTimeout(() => {
        $("#alert_SOP").modal("hide");
      }, 5000);
      // ToastsStore.success("Please Type Your Scheduled of  property");
    }
  };
  ShowTheMessage = () => {
    $("#alert_overview").modal("show");

    setTimeout(() => {
      $("#alert_overview").modal("hide");
    }, 5000);
    // ToastsStore.success("Please Type Your All Documents / Please Type Your Scheduled of  property");
  };
  getDocuments = () => {
    callApi("POST", "doc_list_get.php", { id: this.props.match.params.id })
      .then((res) => res.data)
      .then((response) => {
        let doc_list = this.state.doc_list;
        if (response && response.data && response.data.length) {
          doc_list = response.data;
        }
        this.setState({ doc_list });
        let arrayValue = response.data;
        arrayValue.forEach((element) => {
          let documentValue = element.documents;
          if (
            documentValue != null &&
            documentValue != undefined &&
            documentValue != ""
          ) {
            for (let index = 0; index < documentValue.length; index++) {
              // const element = documentValue[index];
              console.log(
                "element.submit------>>>>",
                documentValue[index].inactive
              );
              console.log(
                "element..submit-----.submit",
                documentValue[index].submit
              );

              if (
                documentValue[index].inactive == 0 &&
                documentValue[index].submit == 0
              ) {
                this.setState({ overVieWorking: true });
                this.setState({ overViewShow: true });
              }
            }
            // documentValue.forEach(element => {
            //   console.log("element.submit------>>>>",element.submit);

            // })
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getRevenueDocs = () => {
    callApi("POST", "rev_list_get.php", { id: this.props.match.params.id })
      .then((res) => res.data)
      .then((response) => {
        let docList: DocList[] = this.state.docList;
        if (response && response.data && response.data.length) {
          docList = response.data;
        }
        this.setState({ docList });
        let arrayValue = response.data;
        let LengthValue = 0;
        arrayValue?.forEach((element) => {
          if (
            element.submit == 0 ||
            (element.submit == 1 && element.inactive == 0)
          ) {
            let revenueCount = LengthValue + 1;
            this.setState({ revenueCount });
          }
          if (this.state.revenueCount == 0) {
            this.setState({ AllDocumentShow: true, overVieWorking: true });
          }
        });
      })

      .catch((err) => {
        console.log(err);
      });
  };

  previousPage = () => {
    const fileurl: any = localStorage.getItem("url");
    $("#in_time").modal("hide");
    sessionStorage.removeItem("currentfileId");
    localStorage.removeItem("thisPopup");
    this.props.history.push(`${fileurl}`);
    localStorage.removeItem("url");
  };
  getInTime = () => {
    if (localStorage.getItem("thisPopup")) {
      $("#in_time").modal("show");
      let date = new Date();
      let curentDate = date.toLocaleDateString();
      localStorage.setItem("Intimedate", JSON.stringify(curentDate));
      var Railway = date.getMinutes();
      if (Railway < 10) {
        var TotalMinutes = "0" + Railway;
        let intime = date.getHours() + ":" + TotalMinutes;
        localStorage.setItem("IntimeGet", JSON.stringify(intime));
      } else {
        let intime: any = date.getHours() + ":" + date.getMinutes();
        this.setState({ intime: intime });
        localStorage.setItem("IntimeGet", JSON.stringify(intime));
      }
      let intime = date.getHours() + ":" + date.getMinutes();
      localStorage.setItem("IntimeGet", JSON.stringify(intime));
      console.log("date", date);
    } else {
      $("#in_time").modal("hide");
    }
  };
  SubmitREsponse = () => {
    if (this.state.in_reason == "") {
      this.setState({ selectVal: true });
    } else {
      // localStorage.setItem("filenumber", this.state.info.file_name);
      localStorage.setItem("inreasonlive", this.state.in_reason);
      localStorage.removeItem("thisPopup");
      this.liveinsert();
      localStorage.removeItem("thisPopup");

      let in_reason = this.state.in_reason;
      localStorage.setItem("in_reason", JSON.stringify(in_reason));
    }
  };

  liveinsert = () => {
    this.setState({ isLoading: true });
    const user: any = sessionStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["team_type"] : "";
    const userId = user ? JSON.parse(user)["userId"] : "";
    const role = user ? JSON.parse(user)["teamType"] : "";
    console.log("214", role);
    const role_type = user ? JSON.parse(user)["roleType"] : "";
    const user_name = user ? JSON.parse(user)["userName"] : "";
    const bankName: any = localStorage.getItem("bankName");
    // localStorage.setItem("fileNamCode", this.state.info.file_name);
    // const in_time : any = localStorage.getItem("IntimeGet");
    // const in_reason : any = localStorage.getItem("in_reason");
    let dateTime = new Date();
    let curentTime = dateTime.toLocaleTimeString();
    console.log("221", curentTime);
    const textRay = `Currently working on File No : ${this.props.match.params.id}`;
    let obj = {
      // file_no: this.state.file_name,
      in_time: curentTime,
      reason: this.state.in_reason,
      user_id: userId,
      user_name: user_name,
      team_type: role,
      role_type: role_type,
      working_process: textRay,
      bank_name: bankName,
    };
    console.log("231", obj);
    callApi("POST", "live_track_insert.php", obj)
      .then((res) => res.data)
      .then((response) => {
        this.setState({ isLoading: false });
        $("#in_time").modal("hide");
        $("#another-reason").modal("hide");
      })
      .catch((err) => {
        console.log(err);
        ToastsStore.error("Your Reason Not Stored");
        this.setState({ isLoading: false });
      });
  };
  onchangeReason = (e: any) => {
    if (e.target.value == "other") {
      this.setState({ in_reason: "", selectVal: false });
      $("#in_time").modal("hide");
      $("#another-reason").modal("show");
    } else if (e.target.value == "select") {
      this.setState({ setResponse: false });
    } else {
      // this.setState({})
      this.setState({ [e.target.name]: e.target.value, selectVal: false });
    }
  };

  getClasses = (modal) => {
    if (this.state.wizard[modal]) {
      return "nav-item active";
    } else {
      return "nav-item";
    }
  };
  goToTab = (modal) => {
    const wizard = {
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      step6: false,
      step7: false,
    };
    wizard[modal] = true;
    this.setState({ wizard });
    if (modal === "step4") {
      this.stopAllMouseClick();
    }
  };
  stopAllMouseClick = () => {
    $("#root").bind("click mousedown dblclick", function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
    });
  };
  handleChange = (e, propName?, fieldType?) => {
    let fieldName = fieldType === "singleselect" ? propName : e.target.name;
    let fieldValue = fieldType === "singleselect" ? e.value : e.target.value;
    this.setState({ [fieldName]: fieldValue });
    if (fieldName !== "reason") {
      this.saveTemplate(fieldValue);
    }
    // Call the customDidMount method of Overview
    if (this.overviewRef.current) {
      sessionStorage.removeItem("overviewDocument");
      setTimeout(() => {
        this.overviewRef.current?.scrutinyReport();
        if (this.overviewRef.current) {
          this.overviewRef.current.setState({ report: "", timer: 0 });
        }
      }, 1000);
    }
  };

  getFileDashboard = () => {
    callApi("POST", "file_dash.php", {
      branch_idfk: this.props.match.params.id,
    })
      .then((res) => res.data)
      .then((response) => {
        const state = response.data;
        const process_officer: any =
          sessionStorage.getItem("process_office_id");
        const userid1: any = sessionStorage.getItem("user1");
        const take_file: any = sessionStorage.getItem("takenfile_id");
        const user: any = sessionStorage.getItem("user");
        // const taken_file = user ? JSON.parse(user)["taken_file"] : take_file;
        const getfileId: any = sessionStorage.getItem("currentfileId");
        if (process_officer == userid1 && take_file == getfileId) {
          if (response.info[0].target_min == 0) {
            $("#balance-time").modal("show");
          } else {
            let initialTimeInMinutes: any = sessionStorage.getItem(
              "updatedRemainingTime"
            );
            if (initialTimeInMinutes > 0) {
              this.setState(
                (prevState: any) => ({
                  initialTimeInMinutes: initialTimeInMinutes,
                }),
                () => { }
              );
            } else if (response.info) {
              this.setState(
                {
                  initialTimeInMinutes: response.info[0].target_min,
                },
                () => { }
              );
            } else {
              this.setState(
                {
                  initialTimeInMinutes: 0,
                },
                () => { }
              );
            }
          }
        }

        if (response.info && response.info.length) {
          state.info = response.info[0];
          state.bank_id = state.info.bank_id;
          if (state.bank_id == 0) {
            ToastsStore.success("Please Select Your Bank Format");
            this.setState({ overViewShow: true });
          }
        }

        this.setState({ ...state });

        let bankTemp: any = this.state.bank_templates.find((get: any) => {
          if (get.id === this.state.bank_id) {
            localStorage.setItem("bankTemplateName", get.name);
            localStorage.setItem("bankTemplatemode", get.mode);
            // this.setState({overViewShow:false})
          }
        });
        $(document).ready(function () {
          $("#simpletable").DataTable();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getTemplates = () => {
    callApi("POST", "bank_temp_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ bank_templates: response.data }, () => {
          let bankTemp: any = this.state.bank_templates.find((get: any) => {
            if (get.id === this.state.bank_id) {
              localStorage.setItem("bankTemplateName", get.name);
              localStorage.setItem("bankTemplatemode", get.mode);
            }
          });
          console.log("bankTemp___________________", bankTemp);
        });
        var bankTempName = [];
        bankTempName = response.data;
        localStorage.setItem("BankTemplate", JSON.stringify(bankTempName));
        // console.log(localStorage.getItem("BankTemplate"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  saveTemplate = (val) => {
    callApi("POST", "bank_temp_save.php", {
      id: this.props.match.params.id,
      bank_id: val,
    })
      .then((res) => res.data)
      .then((response) => {
        // this.setState({overViewShow:false})
        localStorage.removeItem("BankTemplate");
        sessionStorage.removeItem("overviewDocument");
        this.getFileDashboard();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updatedRemainingTime(time) {
    this.setState(
      (prevState: any) => ({
        updatedRemainingTime: time,
      }),
      () => { }
    );
  }
  render() {
    const user: any = sessionStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["role_id"] : "";
    // const role_id = "4";
    const roleType = user ? JSON.parse(user)["roleType"] : "";
    const VerificationToAddon: any = localStorage.getItem(
      "verification_to_addon"
    );
    const { selectVal } = this.state;
    const banktemplatesoption = [
      { value: "", label: "All" },
      ...this.state.bank_templates,
    ];
    const process_officer: any = sessionStorage.getItem("process_office_id");
    const userid1: any = sessionStorage.getItem("user1");
    return (
      <section className="pcoded-main-container">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />{" "}
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <div className="row">
                            <div className="col-md-12">
                              {process_officer == userid1 &&
                                this.state.initialTimeInMinutes &&
                                this.state.initialTimeInMinutes > 0 && (
                                  <CountdownTimer
                                    initialTime={
                                      this.state.initialTimeInMinutes
                                    }
                                    updatedTime={(time) =>
                                      this.updatedRemainingTime(time)
                                    }
                                    ref={(ref) =>
                                      (this.countdownTimerRef = ref)
                                    }
                                  />
                                )}
                            </div>
                            <br />
                          </div>
                          <h5>
                            File #{this.state.info.file_name} | PO:
                            {this.state.info.process_officer} | VO:
                            {this.state.info.verify_officer}
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-10">
                              {/* {
                                VerificationToAddon == "true" && ( */}
                              <div className="template-select">
                                {this.state.bank_templates &&
                                  this.state.bank_templates.length > 0 &&
                                  this.state.bank_templates.map(
                                    (opt: any) => {
                                      opt.label = opt.name;
                                      opt.value = opt.id;
                                    }
                                  ) && (
                                    <Select
                                      options={banktemplatesoption}
                                      name="bank_id"
                                      value={this.state.bank_templates.find(
                                        (o: any) =>
                                          o.value === this.state.bank_id
                                      )}
                                      onChange={(e) =>
                                        this.handleChange(
                                          e,
                                          "bank_id",
                                          "singleselect"
                                        )
                                      }
                                    />
                                  )}
                              </div>
                              {/* )} */}
                            </div>
                            {VerificationToAddon == "true" ? (
                              <>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    this.props.history.push("/work-file-verification-list/" + this.props.match.params.id);
                                    localStorage.setItem("verification_to_addon", JSON.stringify(false));
                                  }}
                                >
                                  Back To Dashboard
                                </button>
                              </>
                            ) : (
                              <>
                                <Link
                                  className="btn btn-primary"
                                  to={
                                    "/file-dashboard/" +
                                    this.props.match.params.id
                                  }
                                >
                                  Back To Dashboard
                                </Link>
                              </>
                            )}
                          </div>
                          <div
                            id="smartwizard"
                            className="sw-main sw-theme-arrows"
                          >
                            {roleType === "10" ||
                              roleType === "9" ||
                              role_id == FRONTOFFICE_ROLE ? (
                              <>
                                <ul className="nav nav-tabs step-anchor">
                                  <li
                                    className={this.getClasses("step7")}
                                    onClick={() => this.goToTab("step7")}
                                  >
                                    <a
                                      className="nav-link"
                                      style={{ paddingTop: "10%" }}
                                    >
                                      <h6 style={{ margin: "13px" }}>
                                        Hint About File{" "}
                                      </h6>
                                    </a>
                                  </li>
                                  <li
                                    className={this.getClasses("step1")}
                                    onClick={() => this.goToTab("step1")}
                                  >
                                    <a className="nav-link">
                                      <h6 style={{ margin: "13px" }}>
                                        Scheduled of <br /> property
                                      </h6>
                                    </a>
                                  </li>
                                  {this.state.AllDocumentShow ? (
                                    <>
                                      {" "}
                                      <li
                                        className={this.getClasses("step2")}
                                        onClick={() => this.goToTab("step2")}
                                      >
                                        <a
                                          className="nav-link"
                                          style={{ paddingTop: "10%" }}
                                        >
                                          <h6 style={{ margin: "13px" }}>
                                            All Documents
                                          </h6>
                                        </a>
                                      </li>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <li onClick={() => this.OpenToaster(2)}>
                                        <a
                                          className="nav-link"
                                          style={{ paddingTop: "10%" }}
                                        >
                                          <h6 style={{ margin: "13px" }}>
                                            All Documents
                                          </h6>
                                        </a>
                                      </li>
                                    </>
                                  )}
                                  <li
                                    className={this.getClasses("step3")}
                                    onClick={() => this.goToTab("step3")}
                                  >
                                    <a className="nav-link">
                                      <h6 style={{ margin: "13px" }}>
                                        List Of <br /> Documents{" "}
                                      </h6>
                                    </a>
                                  </li>
                                </ul>
                              </>
                            ) : VerificationToAddon == "true" ? (
                              <>
                                <ul className="nav nav-tabs step-anchor">
                                  <li
                                    className={this.getClasses("step7")}
                                    onClick={() => this.goToTab("step7")}
                                  >
                                    <a
                                      className="nav-link"
                                      style={{ paddingTop: "10%" }}
                                    >
                                      <h6 style={{ margin: "13px" }}>
                                        Hint About File{" "}
                                      </h6>
                                    </a>
                                  </li>
                                  <li
                                    className={this.getClasses("step1")}
                                    onClick={() => this.goToTab("step1")}
                                  >
                                    <a className="nav-link">
                                      <h6 style={{ margin: "13px" }}>
                                        Scheduled of <br /> property
                                      </h6>
                                      {/* <p className="m-0">
                                    This is step for general details
                                  </p> */}
                                    </a>
                                  </li>

                                  {this.state.AllDocumentShow ? (
                                    <>
                                      {" "}
                                      <li
                                        className={this.getClasses("step2")}
                                        onClick={() => this.goToTab("step2")}
                                      >
                                        <a
                                          className="nav-link"
                                          style={{ paddingTop: "10%" }}
                                        >
                                          <h6 style={{ margin: "13px" }}>
                                            All Documents
                                          </h6>
                                        </a>
                                      </li>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <li onClick={() => this.OpenToaster(2)}>
                                        <a
                                          className="nav-link"
                                          style={{ paddingTop: "10%" }}
                                        >
                                          <h6 style={{ margin: "13px" }}>
                                            All Documents
                                          </h6>
                                        </a>
                                      </li>
                                    </>
                                  )}

                                  <li
                                    className={this.getClasses("step3")}
                                    onClick={() => this.goToTab("step3")}
                                  >
                                    <a className="nav-link">
                                      <h6 style={{ margin: "13px" }}>
                                        List Of <br /> Documents{" "}
                                      </h6>
                                    </a>
                                  </li>
                                  <li
                                    className={this.getClasses("step6")}
                                    onClick={() => this.goToTab("step6")}
                                  >
                                    <a className="nav-link pt-20">
                                      <h6 style={{ margin: "13px" }}>
                                        Addon Features{" "}
                                      </h6>
                                    </a>
                                  </li>
                                </ul>
                              </>
                            ) : (
                              <>
                                <ul className="nav nav-tabs step-anchor">
                                  <li
                                    className={this.getClasses("step7")}
                                    onClick={() => this.goToTab("step7")}
                                  >
                                    <a
                                      className="nav-link"
                                      style={{ paddingTop: "10%" }}
                                    >
                                      <h6 style={{ margin: "13px" }}>
                                        Hint About File{" "}
                                      </h6>
                                    </a>
                                  </li>
                                  <li
                                    className={this.getClasses("step1")}
                                    onClick={() => this.goToTab("step1")}
                                  >
                                    <a className="nav-link">
                                      <h6 style={{ margin: "13px" }}>
                                        Scheduled of <br /> property
                                      </h6>
                                      {/* <p className="m-0">
                                    This is step for general details
                                  </p> */}
                                    </a>
                                  </li>

                                  {this.state.AllDocumentShow ? (
                                    <>
                                      {" "}
                                      <li
                                        className={this.getClasses("step2")}
                                        onClick={() => this.goToTab("step2")}
                                      >
                                        <a
                                          className="nav-link"
                                          style={{ paddingTop: "10%" }}
                                        >
                                          <h6 style={{ margin: "13px" }}>
                                            All Documents
                                          </h6>
                                        </a>
                                      </li>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <li onClick={() => this.OpenToaster(2)}>
                                        <a
                                          className="nav-link"
                                          style={{ paddingTop: "10%" }}
                                        >
                                          <h6 style={{ margin: "13px" }}>
                                            All Documents
                                          </h6>
                                        </a>
                                      </li>
                                    </>
                                  )}

                                  <li
                                    className={this.getClasses("step3")}
                                    onClick={() => this.goToTab("step3")}
                                  >
                                    <a className="nav-link">
                                      <h6 style={{ margin: "13px" }}>
                                        List Of <br /> Documents{" "}
                                      </h6>
                                    </a>
                                  </li>
                                  <li
                                    className={this.getClasses("step6")}
                                    onClick={() => this.goToTab("step6")}
                                  >
                                    <a className="nav-link pt-20">
                                      <h6 style={{ margin: "13px" }}>
                                        Addon Features{" "}
                                      </h6>
                                    </a>
                                  </li>

                                  {VerificationToAddon !== "true" && (
                                    <>
                                      {this.state.overViewShow ? (
                                        <>
                                          <li
                                            className={this.getClasses("step4")}
                                            onClick={() =>
                                              this.ShowTheMessage()
                                            }
                                          >
                                            <a className="nav-link">
                                              <h6 style={{ margin: "13px" }}>
                                                Documents <br /> Overview{" "}
                                              </h6>
                                            </a>
                                          </li>
                                        </>
                                      ) : (
                                        <li
                                          className={this.getClasses("step4")}
                                          onClick={() => this.goToTab("step4")}
                                        >
                                          <a className="nav-link">
                                            <h6 style={{ margin: "13px" }}>
                                              Documents <br /> Overview{" "}
                                            </h6>
                                          </a>
                                        </li>
                                      )}
                                    </>
                                  )}
                                </ul>
                              </>
                            )}
                            <div className="content">
                              {this.state.wizard.step1 && (
                                <GeneralDetail
                                  id={this.state.id}
                                  doclist={this.state.docList}
                                />
                              )}
                              {this.state.wizard.step2 && (
                                <DocumentList
                                  id={this.state.id}
                                  doclist={this.state.doc_list}
                                />
                              )}
                              {this.state.wizard.step3 && (
                                <ScrutinizedDocument
                                  id={this.state.id}
                                  history={this.props.history}
                                />
                              )}
                              {/* {this.state.wizard.step4 && (
                                    <PropertyDescription id={this.state.id} />
                                  )}
                                  {this.state.wizard.step5 && (
                                    <CheckList id={this.state.id} />
                                  )} */}
                              {this.state.wizard.step4 && (
                                <Overview
                                  ref={this.overviewRef}
                                  id={this.state.id}
                                  isFinal={false}
                                  history={this.props.history}
                                  fileId={this.props.match.params.id}
                                />
                              )}
                              {this.state.wizard.step5 && (
                                <Overview
                                  id={this.state.id}
                                  isFinal={true}
                                  history={this.props.history}
                                  fileId={this.props.match.params.id}
                                />
                              )}
                              {this.state.wizard.step6 && (
                                <AddOnFeature id={this.state.id} />
                              )}
                              {this.state.wizard.step7 && (
                                <HintAboutFile id={this.state.id} path='index' />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg"
          id="alert_overview"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog" style={{ maxWidth: "751px" }}>
            <div
              className="modal-content"
              style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
            >
              <div
                className="modal-body"
                style={{ padding: "32px", paddingBottom: "0px" }}
              >
                <h5 style={{ textAlign: "center" }}>
                  Please Type Your All Documents / Please Type Your Scheduled of
                  property
                </h5>
              </div>
              <div style={{ border: "none" }} className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.closeToasterOverview()}
                  // onClick={this.disableModalHandler}
                  style={{ width: "90px", margin: "auto", fontSize: "17px" }}
                >
                  {" "}
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal hide fade in"
          id="alert_SOP"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog ">
            <div
              className="modal-content"
              style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
            >
              <div
                className="modal-body"
                style={{ padding: "32px", paddingBottom: "0px" }}
              >
                <h5 style={{ textAlign: "center" }}>
                  Please Type Your Scheduled of property
                </h5>
              </div>
              <div style={{ border: "none" }} className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.closeToasterSOP()}
                  // onClick={this.disableModalHandler}
                  style={{ width: "90px", margin: "auto", fontSize: "17px" }}
                >
                  {" "}
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal hide fade in"
          id="alert_alldocs"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog ">
            <div
              className="modal-content"
              style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
            >
              <div
                className="modal-body"
                style={{ padding: "32px", paddingBottom: "0px" }}
              >
                <h5 style={{ textAlign: "center" }}>
                  Please Type Your All Documents
                </h5>
              </div>
              <div style={{ border: "none" }} className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.closeToaster()}
                  // onClick={this.disableModalHandler}
                  style={{ width: "90px", margin: "auto", fontSize: "17px" }}
                >
                  {" "}
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal hide fade in"
          id="in_time"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog ">
            <div
              className="modal-content"
              style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
            >
              <div
                className="modal-body"
                style={{ padding: "30px 23px 29px 24px" }}
              >
                <h5>
                  Please Select a Reason To Open File.No:{" "}
                  {this.props.match.params.id}
                </h5>
                <div
                  className="form-group"
                  style={{ width: "74%", margin: "auto" }}
                >
                  <select
                    className="custom-select"
                    name="in_reason"
                    value={this.state.in_reason.trimStart()}
                    onChange={(e) => this.onchangeReason(e)}
                    required
                    style={{ marginTop: "24px" }}
                  >
                    <option value="select">Select.</option>
                    <option value="New Login">New Login</option>
                    <option value="Manual">Manual</option>
                    <option value="Query Received">Query Received</option>
                    <option value="Previous Login Pending">
                      Previous Login Pending
                    </option>
                    <option value="Previous Query Pending">
                      Previous Query Pending
                    </option>
                    <option value="Re-Assign">Re-Assign</option>
                    <option value="Doubts&Clarification">
                      Doubts/Clarification
                    </option>
                    <option value="Verification">Verification</option>
                    <option value="Data Entry">Data Entry</option>
                    <option value="Vetting">Vetting</option>
                    <option value="other">Others</option>
                  </select>
                  {selectVal ? (
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                    >
                      Please Select Your Reason{" "}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.SubmitREsponse()}
                  disabled={this.state.isLoading}
                  style={{ width: "76px", fontSize: "17px" }}
                >
                  {" "}
                  {this.state.isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    "OK"
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={() => this.previousPage()}
                  // onClick={this.disableModalHandler}
                  style={{
                    width: "76px",
                    fontSize: "17px",
                    border: "1px solid black",
                  }}
                >
                  {" "}
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal hide fade in"
          id="another-reason"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog ">
            <div
              className="modal-content"
              style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
            >
              <h5 style={{ marginTop: "31px", textAlign: "center" }}>
                Please Type a Reason To Open File.No:{" "}
                {this.props.match.params.id}{" "}
              </h5>

              <div
                className="modal-body"
                style={{ padding: "30px 23px 29px 24px" }}
              >
                {/* <div className="col-md-5"> */}
                <div
                  className="form-group"
                  style={{ width: "98%", margin: "auto" }}
                >
                  <textarea
                    typeof="text"
                    className="form-control"
                    placeholder="Type Your Reason"
                    name="in_reason"
                    onChange={this.onchange}
                  />
                  {selectVal ? (
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                    >
                      Please Type Your Reason{" "}
                    </p>
                  ) : null}
                </div>
                {/* </div> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.SubmitREsponse()}
                  style={{ width: "76px", fontSize: "17px" }}
                  disabled={this.state.isLoading}
                >
                  {" "}
                  {this.state.isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    "OK"
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={() => this.previousPop()}
                  style={{
                    width: "76px",
                    fontSize: "17px",
                    border: "1px solid black",
                  }}
                >
                  {" "}
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
