import * as React from "react";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import { Link } from "react-router-dom";
import { callApi } from "../../utils/api";
import AssignBankUser from "./AssignBankUser";
import FileSearchModal from "./FileSearchModal";
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from "react-toasts";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Select,
  MenuItem
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import moment from "moment";

interface AllProps {
  history: any;
}
declare var $: any;
class manageInprogressFile extends React.Component<AllProps> {
  limitList = [20, 40, 60]
  state = {
    selectVal: false,
    outreason: "",
    files: [],
    fileToBeAssigned: 0,
    isFileRefresh: false,
    pageNumber: 1,
    rowCount: this.limitList[0],
    totalCount: 0,
    takeFiles: [],
    autoAssign: [
      {
        "id": "1",
        "module_name": "Auto Assign",
        "status": true
      }
    ],
    takenfiles: [],
    queuefiles: "",
    takeTime: false,
    isLoading: false
  };
  componentDidMount() {
    this.gettakenFiles();
    this.getOutTime();
    this.ouTime();
    // this.getAutoAssignStatus();
    // $('#no-queue-popup').modal('show');
    this.state.autoAssign.find(
      (data: any) => {
        if (data.module_name === "Auto Assign" && data.status == false) {
          this.getFiles(this.state.pageNumber, this.state.rowCount);
        } else {
          this.getqueueFiles();
        }
      });
  }
  getAutoAssignStatus = () => {
    callApi("GET", "module_option_select.php", "")
      .then((res) => res.data)
      .then((result) => {
        this.setState({ autoAssign: result.responseJson.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  GotoWorkFile = (id, process_office_id, vtaken_status, reassignStatus) => {
    const userid1: any = sessionStorage.getItem("user1");
    if (vtaken_status != 1) {
      let process_officer_id = JSON.stringify(process_office_id);
      sessionStorage.setItem("process_office_id", process_officer_id);
      let obj = { file_id: id };
      localStorage.setItem("thisPopup", JSON.stringify(obj));
      sessionStorage.setItem("currentfileId", JSON.stringify(id));
      const take_file: any = sessionStorage.getItem("takenfile_id");
      const user: any = sessionStorage.getItem("user");
      // const taken_file = user ? JSON.parse(user)["taken_file"] : take_file;
      const getfileId: any = sessionStorage.getItem("currentfileId");
      if (process_officer_id == userid1 && take_file != "" && take_file == getfileId) {
        this.timeUpdate(id, "workfile", reassignStatus);
        console.log("process officer");
      } else {
        console.log("not process officer");
      }
      this.props.history.push("/work-file/7/" + id);
    } else {
      this.verificationlist(id, userid1, reassignStatus);
    }

  };
  timeUpdate = (fileid, type, reassignStatus) => {
    const loginuser: any = sessionStorage.getItem("user1");
    let req;
    this.state.autoAssign.find(
      (data: any) => {
        if (data.module_name === "Auto Assign" && data.status == true) {
          if (type == "workfile") {
            req = {
              work_in_progress_by: JSON.parse(loginuser),
              id: fileid,
              taken_status: 1,
              reason: "Work Inprogress",
              created_by: JSON.parse(loginuser),
              color: "#aceaaf"
            }
          } else if (type == "filedash" && reassignStatus == "1") {
            req = {
              work_in_progress_by: JSON.parse(loginuser),
              id: fileid,
              taken_status: 1,
              reason: "Process Reassign",
              created_by: JSON.parse(loginuser),
              color: "#aceaaf"
            }
          } else if (type == "filedash" && reassignStatus == "0") {
            req = {
              work_in_progress_by: JSON.parse(loginuser),
              id: fileid,
              taken_status: 1,
              reason: "Work Inprogress",
              created_by: JSON.parse(loginuser),
              color: "#aceaaf"
            }
          } else if (type == "verification" && reassignStatus == "1") {
            req = {
              id: fileid,
              verification_picked_by: JSON.parse(loginuser),
              reason: "Verification Reassign",
              created_by: JSON.parse(loginuser),
              color: "#faad4c"
            };
          } else if (type == "verification" && reassignStatus == "0") {
            req = {
              id: fileid,
              verification_picked_by: JSON.parse(loginuser),
              reason: "Verification Picked",
              created_by: JSON.parse(loginuser),
              color: "#faad4c"
            };
          } else {
            req = {
              id: fileid,
              reason: "File Out",
              created_by: JSON.parse(loginuser)
            }
            sessionStorage.removeItem("currentfileId");
            localStorage.removeItem("query_raised_status");
            localStorage.removeItem("file_completed_status");
            // sessionStorage.removeItem("takenfile_id");
          }
        }
      })
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {
      });
  }

  previousPop = () => {
    $("#out_reason").modal("show");
    $("#another-reason").modal("hide");
  }
  BacktoFile = () => {
    $("#out_reason").modal("hide");
    const fileid1: any = sessionStorage.getItem("currentfileId");
    this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
  }
  getOutTime = () => {
    let date = new Date();
    let stratTime = date.getTime();
    localStorage.setItem("startTime", JSON.stringify(stratTime))
    let outDate = date.toLocaleDateString();
    localStorage.setItem("OutDateGet", JSON.stringify(outDate));
    var Railway = date.getMinutes();
    if (Railway < 10) {
      var TotalMinutes = "0" + Railway;
      let ouTtime = date.getHours() + ":" + TotalMinutes;
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }
    else {
      let ouTtime: any = date.getHours() + ":" + date.getMinutes();
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }

  }
  ouTime = () => {
    const getfileId: any = sessionStorage.getItem("currentfileId");
    console.log("54", JSON.parse(getfileId))
    console.log("32", getfileId);
    if (sessionStorage.getItem("currentfileId")) {
      $("#out_reason").modal("show");
    }
  }

  outimeGet = () => {
    this.setState({ isLoading: true });
    const fileid1: any = sessionStorage.getItem("currentfileId");
    const userid1: any = sessionStorage.getItem("user1");
    const inreason: any = localStorage.getItem("in_reason");
    const intime: any = localStorage.getItem("IntimeGet");
    console.log("70", intime);
    const outtime: any = localStorage.getItem("outtimeGet");
    console.log("73", outtime);
    const outdate: any = localStorage.getItem("OutDateGet");
    const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
    console.log('Second Spn', secondsSpent);
    let updatedRemainingTime: any = sessionStorage.getItem('updatedRemainingTime');
    let updatedtime = Math.round(updatedRemainingTime);
    const status: any = sessionStorage.getItem("dashboard_status");
    if (this.state.outreason == "") {
      this.setState({ selectVal: true });
    }
    else {
      let obj = {
        "file_id": JSON.parse(fileid1),
        "user_id": JSON.parse(userid1),
        "in_reason": JSON.parse(inreason),
        "out_reason": this.state.outreason,
        "intime": JSON.parse(intime),
        "outtime": JSON.parse(outtime),
        "insert_date": JSON.parse(outdate),
        "total_seconds": secondsSpent,
        "balance_min": updatedtime,
        "dashboard_status": status
      }

      // console.log("72",obj);
      callApi("POST", "process_eod_insert.php", obj)
        .then(res => res.data)
        .then(response => {
          this.setState({ isLoading: false });
          sessionStorage.removeItem('updatedRemainingTime');
          localStorage.removeItem("in_reason");
          localStorage.removeItem("outreason");
          localStorage.removeItem("IntimeGet");
          localStorage.removeItem("OutDateGet");
          $("#out_reason").modal("hide");
          $("#another-reason").modal("hide");
          ToastsStore.success("Your Reason Saved Successfully");
          let process_officerId: any = sessionStorage.getItem("process_office_id");
          let process_officer_id = JSON.parse(process_officerId);
          const userid1: any = sessionStorage.getItem("user1");
          let user_id = JSON.parse(userid1);
          let file_id = JSON.parse(fileid1);
          const take_file: any = sessionStorage.getItem("takenfile_id");
          const user: any = sessionStorage.getItem("user");
          this.gettakenFiles();
          const query_status: any = localStorage.getItem("query_raised_status");
          const file_completed = localStorage.getItem("file_completed_status");
          if (process_officer_id == user_id && take_file != "" && take_file === fileid1 && query_status == "false" && file_completed == "false") {
            this.timeUpdate(file_id, "fileout", '');
          } else {
            sessionStorage.removeItem('currentfileId');
            localStorage.removeItem("query_raised_status");
            localStorage.removeItem("file_completed_status");
          }

        }).catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
          ToastsStore.error("Your Reason Not Stored");
        })
    }


  }
  onchangeReason = (e: any) => {

    if (e.target.value == "Other") {
      this.setState({ in_reason: "", selectVal: false })
      $("#out_reason").modal("hide");
      $("#another-reason").modal("show");
    }
    else if (e.target.value == "select") {
      this.setState({ setResponse: false })
    }
    else {
      // this.setState({})
      this.setState({ [e.target.name]: e.target.value, selectVal: false });
    }
  };
  onchange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
  };
  getqueueFiles = () => {
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const user_grade = user ? JSON.parse(user)["user_grade"] : "";
    callApi("POST", "get_by_status.php", { status: 0, userId, user_grade })
      .then((res) => res.data)
      .then((response) => {
        const files = response.data;
        this.setState({ queuefiles: files.length, isFileRefresh: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getFiles = (page_id, row_count) => {
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "get_by_status.php", { status: 2, userId, page_id, row_count })
      .then(res => res.data)
      .then(response => {
        const files = response.data;
        const totalCount = response.count;
        // if (files && files.length > 0) {
        //   files.forEach((file, index) => {
        //     Object.keys(file).forEach(fileKey => {
        //       file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
        //     })
        //     file.index = index + 1;
        //   })
        // }
        this.setState({ files, totalCount, isFileRefresh: true });
      })
      .catch(err => {
        console.log(err);
      });
  };
  gettakenFiles = () => {
    this.setState({ isFileRefresh: false })
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const roleid = user ? JSON.parse(user)["role_id"] : "";
    const roletype = user ? JSON.parse(user)["roleType"] : "";
    const req = {
      user_id: userId,
      role_id: roleid,
      role_type: roletype
    }
    callApi("POST", "taken_file.php", req)
      .then(res => res.data)
      .then(response => {
        const files = response.file_id;
        if (files && files.length > 0) {
          files.forEach((file, index) => {
            Object.keys(file).forEach(fileKey => {
              file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
            })
            file.index = index + 1;
          })
          sessionStorage.setItem("takenfile_id", JSON.stringify(files[0].id));
        }
        this.setState({ takenfiles: files, isFileRefresh: true });
      })
      .catch(err => {
        console.log(err);
      });
  };
  assignFile = (modal: any) => {
    this.setState({ fileToBeAssigned: modal.id });
  };
  goToFileDashboard = (id, process_office_id, vtaken_status, reassignStatus) => {
    let userid1: any = sessionStorage.getItem("user1");
    if (vtaken_status != 1) {
      let process_officer_id = JSON.stringify(process_office_id);
      sessionStorage.setItem("process_office_id", process_officer_id);
      const url = `/inprogress-file`;
      localStorage.setItem("url", url);
      let obj = { "file_id": id }
      localStorage.setItem("thisPopup", JSON.stringify(obj))
      sessionStorage.setItem("currentfileId", JSON.stringify(id));

      const take_file: any = sessionStorage.getItem("takenfile_id");
      const user: any = sessionStorage.getItem("user");
      // const taken_file = user ? JSON.parse(user)["taken_file"] : take_file;

      const getfileId: any = sessionStorage.getItem("currentfileId");
      if (process_officer_id == userid1 && take_file != "" && take_file == getfileId) {
        this.timeUpdate(id, "filedash", reassignStatus);
        console.log("process officer");
      } else {
        console.log("not process officer");
      }
      this.props.history.push("/file-dashboard/" + id);
    } else {
      this.verificationlist(id, userid1, reassignStatus);
    }
  };
  fileSearch = modal => {
    this.setState({ isFileRefresh: false })
    callApi("POST", "file_search.php", modal)
      .then(res => res.data)
      .then(response => {
        const files: any = [];
        if (response.data && response.data.length > 0) {
          response.data.forEach((file: any, index: number) => {
            Object.keys(file).forEach(fileKey => {
              file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
            })
            file.index = index + 1;
            files.push(file)
          })
        }
        this.setState({ files, takenfiles: files, isFileRefresh: true });
      })
      .catch(err => {
        console.log(err);
      })
  }
  handlePageLimit = (event) => {
    const rowCount = event.target.value;
    const pageNumber = 1;
    this.setState({ pageNumber, rowCount });
    this.getFiles(pageNumber, rowCount);
  }
  handlePagination = (event, value) => {
    this.setState({ pageNumber: value });
    this.getFiles(value, this.state.rowCount);
  }

  //take new file from auto assign
  gettakefile = () => {
    sessionStorage.setItem("autoassign_query", '');
    var randomNumber = Math.floor(Math.random() * (30 - 10 + 1)) + 10;
    console.log("randomNumber", randomNumber);
    this.setState({ takeTime: true });
    setTimeout(() => {
      console.log("randomNumber--", randomNumber);
      this.setState({ takeTime: false });
    }, randomNumber * 1000);
    this.setState({ isFileRefresh: false })
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const roleid = user ? JSON.parse(user)["role_id"] : "";
    const roletype = user ? JSON.parse(user)["roleType"] : "";
    const user_grade: any = sessionStorage.getItem("user_grade");
    const e_grade: any = sessionStorage.getItem("e_grade");
    const v_grade: any = sessionStorage.getItem("v_grade");
    const req = {
      user_id: userId,
      role_id: roleid,
      role_type: roletype,
      user_grade: user_grade,
      e_grade: e_grade,
      v_grade: v_grade
    }
    callApi("POST", "auto_assign.php", req)
      .then(res => res.data)
      .then(response => {
        const files: any = [];
        if (response && response.file_id) {
          let fileid = response.file_id;
          let reassignStatus = response.reassignStatus;
          let processoffice_id = response.process_office_id;
          let sql = response.sql;
          let type = response.type;
          sessionStorage.setItem("takenfile_id", JSON.stringify(fileid));
          sessionStorage.setItem("process_office_id", processoffice_id);
          sessionStorage.setItem("verification_office_id", userId);
          sessionStorage.setItem("autoassign_query", sql);
          sessionStorage.setItem("autoassign_work_type", type);
          let redirect_to = response.type;
          if (redirect_to == "w_f") {
            this.goToFileDashboard(fileid, processoffice_id, 0, reassignStatus);
          } else {
            this.verificationlist(fileid, userId, reassignStatus);
          }
        } else {
          $('#no-queue-popup').modal('show');
        }
        this.setState({ takeFiles: files, isFileRefresh: true });
      })
      .catch(err => {
        console.log(err);
      })
  }

  verificationlist = (file_id: any, id: any, reassignStatus) => {
    sessionStorage.setItem("verification_office_id", id);
    const verification_officer = id;
    const userid1: any = sessionStorage.getItem("user1");
    let user_id = JSON.parse(userid1);
    if (verification_officer == user_id) {
      console.log("verificationlist");
      this.timeUpdate(file_id, "verification", reassignStatus);
    }
    window.location.href = "/work-file-verification-list/" + file_id;
  }

  render() {
    const columns = [
      {
        header: 'ID',
        key: 'id',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id, file.vtaken_status, file.reassignStatus)
        }>{file.index}</td>
      },
      {
        header: "Actions",
        td: (file) => {
          return <div className="btn-group mb-2 mr-2">
            <button
              type="button"
              className="btn btn-primary"
            >
              Action
            </button>
            <button
              type="button"
              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="sr-only">
                Toggle Dropdown
              </span>
            </button>
            <div
              className="dropdown-menu"
              x-placement="top-start"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                transform:
                  "translate3d(88px, -2px, 0px)"
              }}
            >
              {this.state.autoAssign && this.state.autoAssign.find(
                (data: any) => data.module_name === "Auto Assign" && data.status == false) ?
                <>
                  <a
                    className="dropdown-item"
                    onClick={() =>
                      this.assignFile(file)
                    }
                    data-toggle="modal"
                    data-target="#assign-modal"
                    data-whatever="@mdo"
                  >
                    Reassign
                  </a>
                </>
                : ""}
              <button
                onClick={() => this.GotoWorkFile(file.id, file.process_office_id, file.vtaken_status, file.reassignStatus)}
                style={{
                  border: "none",
                  background: "none",
                  display: "block",
                  margin: "auto",
                }}
              >
                Work File
              </button>
            </div>
          </div >
        }
      },
      {
        header: 'File ID',
        key: 'file_id',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id, file.vtaken_status, file.reassignStatus)
        }>{file.file_id}</td>
      },
      {
        header: 'Bank Name',
        key: 'bank_name',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id, file.vtaken_status, file.reassignStatus)
        }> {file.bank_name} {file.product_name ? <>({file.product_name})</> : ""}</td>
      },
      {
        header: 'Bank Branch Name',
        key: 'branch_name',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id, file.vtaken_status, file.reassignStatus)
        }>{file.branch_name}</td>
      },
      {
        header: 'Property Owner Name',
        key: 'property_ownername',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id, file.vtaken_status, file.reassignStatus)
        }>{file.property_ownername}</td>
      },
      {
        header: 'Law Hands Branch',
        key: 'law_hand_branch',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id, file.vtaken_status, file.reassignStatus)
        }>{file.law_hand_branch}</td>
      },
      {
        header: 'Login By',
        key: 'login_by',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id, file.vtaken_status, file.reassignStatus)
        }>{file.login_by}</td>
      },
      {
        header: 'To Follow',
        key: 'assigned_to',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id, file.vtaken_status, file.reassignStatus)
        }>{file.assigned_to}</td>
      },
      {
        header: 'Created Date',
        key: 'date_time',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id, file.vtaken_status, file.reassignStatus)
        }>{file.date_time}</td>
      }
    ];
    const data = this.state.takenfiles;
    const { selectVal } = this.state;
    const noOfPages = Math.ceil(this.state.totalCount / this.state.rowCount);
    return (
      <section className="pcoded-main-container">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />{" "}
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="file-status-header">Work In Progress Files Manage</h5>
                          <div className="file-search-button">
                            {this.state.autoAssign && this.state.autoAssign.find(
                              (data: any) => data.module_name === "Auto Assign" && data.status == false) ?
                              <>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  data-toggle="modal"
                                  data-target="#file-search-modal"
                                >
                                  File Search
                                </button>
                              </> : ""}

                          </div>
                        </div>
                        {this.state.autoAssign && this.state.autoAssign.find(
                          (data: any) => data.module_name === "Auto Assign" && data.status) ?
                          <>
                            <div className="card-body" style={{ marginTop: "2%" }}>
                              {!data ?
                                <>
                                  <div className="" style={{ display: "flex", justifyContent: "center", padding: "18%" }}>
                                    <div style={{ textAlign: "center" }}>
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={this.gettakefile}
                                        disabled={this.state.takeTime}
                                      >
                                        Take file
                                      </button><br />
                                      <b style={{ fontSize: "17px" }}>{this.state.queuefiles ? this.state.queuefiles : "0"} Files Waiting in Queue</b>
                                    </div>
                                  </div>
                                </> :
                                <>
                                  {data && this.state.isFileRefresh &&
                                    <>
                                      {data.length > 0 &&
                                        <ReactFlexyTable data={data} columns={columns} sortable globalSearch />
                                      }
                                    </>
                                  }</>
                              }
                            </div>
                          </> : <>
                            <div className="card-body">
                              {this.state.isFileRefresh &&
                                <>
                                  <TableContainer className="table-container mt-4">
                                    <Table stickyHeader aria-label="sticky table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell >S.NO</TableCell>
                                          <TableCell>Actions</TableCell>
                                          <TableCell>File ID</TableCell>
                                          <TableCell>Bank Name</TableCell>
                                          <TableCell>Bank Branch Name</TableCell>
                                          <TableCell>Property Owner Name</TableCell>
                                          <TableCell>Law Hands Branch</TableCell>
                                          <TableCell>Login By</TableCell>
                                          <TableCell>To Follow</TableCell>
                                          <TableCell>Created Date</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {this.state.files && this.state.files.length > 0 && this.state.files.map((file: any, index) => {
                                          const { id, file_id, bank_name, branch_name, property_ownername, law_hand_branch, login_by, assigned_to, date_time, process_office_id, vtaken_status, reassignStatus } = file;
                                          const props = { onClick: () => this.goToFileDashboard(id, process_office_id, vtaken_status, reassignStatus) }
                                          const serialNumber = this.state.rowCount * (this.state.pageNumber - 1) + index + 1
                                          return (
                                            <TableRow hover tabIndex={-1} key={index} className="cursor-pointer">
                                              <TableCell className="index-table-cell" {...props}>{serialNumber}</TableCell>
                                              <TableCell>
                                                <div className="btn-group mb-2 mr-2">
                                                  <button type="button" className="btn btn-primary">
                                                    Action
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  >
                                                    <span className="sr-only">Toggle Dropdown</span>
                                                  </button>
                                                  <div
                                                    className="dropdown-menu"
                                                    x-placement="top-start"
                                                    style={{
                                                      position: "absolute",
                                                      top: "0px",
                                                      left: "0px",
                                                      transform:
                                                        "translate3d(88px, -2px, 0px)"
                                                    }}
                                                  >
                                                    <a
                                                      className="dropdown-item"
                                                      onClick={() =>
                                                        this.assignFile(file)
                                                      }
                                                      data-toggle="modal"
                                                      data-target="#assign-modal"
                                                      data-whatever="@mdo"
                                                    >
                                                      Reassign
                                                    </a>
                                                    <button
                                                      onClick={() => this.GotoWorkFile(file.id, file.process_office_id, file.vtaken_status, reassignStatus)}
                                                      style={{
                                                        border: "none",
                                                        background: "none",
                                                        display: "block",
                                                        margin: "auto",
                                                      }}
                                                    >
                                                      Work File
                                                    </button>
                                                    {/* <Link to={`/work-file/7/${id}`} className="dropdown-item">Work File</Link> */}
                                                  </div>
                                                </div>
                                              </TableCell>
                                              <TableCell {...props}>{file_id}</TableCell>
                                              <TableCell {...props}>{bank_name}</TableCell>
                                              <TableCell {...props}>{branch_name}</TableCell>
                                              <TableCell {...props}>{property_ownername}</TableCell>
                                              <TableCell {...props}>{law_hand_branch}</TableCell>
                                              <TableCell {...props}>{login_by}</TableCell>
                                              <TableCell {...props}>{assigned_to}</TableCell>
                                              <TableCell {...props}>{date_time}</TableCell>
                                            </TableRow>
                                          )
                                        })
                                        }
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                  <div className="pagination-container">
                                    <div>
                                      <span>Show</span>
                                      <Select className="select-container" variant="outlined" value={this.state.rowCount} onChange={e => this.handlePageLimit(e)}>
                                        {this.limitList.map((limitValue, index) => (
                                          <MenuItem key={index} value={limitValue}>
                                            {limitValue}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      <span>Rows</span>
                                    </div>
                                    {noOfPages > 1 && (
                                      <Pagination
                                        count={noOfPages}
                                        defaultPage={this.state.pageNumber}
                                        onChange={this.handlePagination}
                                        showFirstButton
                                        showLastButton
                                      />
                                    )}
                                  </div>
                                </>
                              }
                            </div>
                          </>}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal"
          id="no-queue-popup"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document" style={{
            display: "grid !importent", placeItems: "center !importent", margin: "10% auto !importent", justifyContent: "center !importent"
          }}>
            <div className="modal-content">
              <div className="modal-header">
                {/* <h5 className="modal-title">Auto Assign Enable & Disable</h5> */}
                {/* <h5 className="modal-title">In Queue there is no Files, please wait for some times.</h5> */}
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <h5 className="modal-title" style={{ textAlign: "center" }}>In Queue there is no Files, please wait for some times.</h5>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>


        {/* THIS FOR OUT REASON MODAL */}
        <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
              </div>
              <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                <select
                  className="custom-select"
                  name="outreason"
                  value={this.state.outreason}
                  onChange={(e) => this.onchangeReason(e)}
                  required
                  id="select1"
                  style={{ marginTop: '8px' }}
                >
                  <option value="select">Select Your Reason</option>
                  <option value="Query Raised">Query Raised</option>
                  <option value=" Re-Query Raised"> Re-Query Raised</option>
                  <option value="Hold ">Hold </option>
                  <option value="Completed">Completed</option>
                  <option value="Other">Others</option>

                </select>
                {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
              </div>
              <div className="modal-footer" style={{ marginTop: '27px' }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={this.state.isLoading}
                  onClick={this.outimeGet}
                  style={{ width: '76px', fontSize: '17px' }}
                > {this.state.isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : "OK"}</button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={this.BacktoFile}
                  style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                > Back To File</button>
              </div>
            </div>
          </div>
        </div>
        {/* OTHER REASON MODAL */}
        <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                {/* <div className="col-md-5"> */}
                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                  <textarea
                    className="form-control"
                    placeholder="Type Your Reason"
                    name="outreason" onChange={this.onchange} />
                  {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                </div>
                {/* </div> */}

              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.outimeGet()}
                  style={{ width: '76px', fontSize: '17px' }}

                >{this.state.isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : "OK"}</button>
                <button
                  type="button"
                  className="btn btn-outline"

                  onClick={() => this.previousPop()}
                  // onClick={this.disableModalHandler}
                  style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                > Back</button>
              </div>
            </div>
          </div>
        </div>
        <AssignBankUser
          fileToBeAssigned={this.state.fileToBeAssigned}
          getFiles={this.getFiles}
          assignedFrom={"inprogress"}
        />
        <FileSearchModal fileStatus={2} searchFileCallback={(data) => this.fileSearch(data)} />
      </section >
    );
  }
}
export default manageInprogressFile;
